import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Card, Column, Image, Row } from "../common";
import { MEDIA, SCREENSHOTS } from "../../constants";
import { ReactNode } from "react";

const CARD_PADDING = 3;
const SPACING = 3;
const BREAKPOINT = MEDIA.LG;
export default function Bento() {
  const isMobile = useMediaQuery(BREAKPOINT.below);
  return (
    <Column spacing={6}>
      <Typography variant="h1">
        The future of credit is market-driven and modular.
      </Typography>
      {isMobile ? <Mobile /> : <Desktop />}
    </Column>
  );
}

function Desktop() {
  return (
    <Row alignItems="stretch" spacing={SPACING}>
      <Column width="65%" spacing={SPACING}>
        <Row alignItems="stretch" spacing={SPACING}>
          <Duration />
          <Categories />
        </Row>
        <Terms />
      </Column>
      <Column width="35%">
        <Rates />
      </Column>
    </Row>
  );
}

function Mobile() {
  return (
    <Stack spacing={SPACING}>
      <Rates />
      <Duration />
      <Terms />
      <Categories />
    </Stack>
  );
}

function Duration() {
  return (
    <HeaderCard
      header="Powerful borrow structures"
      description="Choose from fixed-duration, fixed-rate, and multi-asset borrowing options."
    >
      <Row sx={{ justifyContent: "flex-end", overflow: "hidden" }}>
        <Image
          alt="terms"
          sx={{ mr: -2 }}
          mobileSx={{ mb: CARD_PADDING }}
          query={BREAKPOINT}
          variant="fixed-width"
          width="100%"
          height="auto"
          mobileHeight="200px"
          src={SCREENSHOTS.duration}
        />
      </Row>
    </HeaderCard>
  );
}

function Terms() {
  return (
    <Card
      sx={{
        p: 0,
        gap: 0,
        [BREAKPOINT.above]: {
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          pr: 3,
        },
      }}
    >
      <Header
        header="Tailored risk"
        description="Outperform one-size-fits-all models. Isolated collateral and customizable terms eliminate systemic risk."
      />

      <Image
        alt="durations"
        variant="fixed-width"
        width="auto"
        src={SCREENSHOTS.terms}
      />
    </Card>
  );
}

function Categories() {
  return (
    <HeaderCard
      header="Leverage for any asset"
      description="Liquidity positions. Memecoins. Staked SOL. Tap into $100B+ of latent ecosystem value supported only on Loopscale."
    >
      <Image
        alt="token categories"
        sx={{ mb: CARD_PADDING }}
        variant="fixed-width"
        query={BREAKPOINT}
        width="100%"
        height="auto"
        mobileHeight="150px"
        src={SCREENSHOTS.categories}
      />
    </HeaderCard>
  );
}

function Rates() {
  return (
    <HeaderCard
      header="Lend rate = borrow rate"
      description={[
        "Optimal yield is impossible in pool-based models and most blockchains can’t support order books.",
        "Get better rates for both borrowing and lending on Loopscale’s Solana-powered order book.",
      ]}
    >
      <Image
        alt="rates"
        variant="fixed-width"
        width="100%"
        height="auto"
        mobileHeight="300px"
        query={BREAKPOINT}
        sx={{ alignSelf: "center" }}
        mobileSx={{ alignSelf: "center", mb: -10 }}
        src={SCREENSHOTS.rates}
      />
    </HeaderCard>
  );
}

type HeaderProps = {
  header: string;
  description: string | string[];
};

function HeaderCard({
  children,
  ...headerProps
}: {
  children: ReactNode;
} & HeaderProps) {
  return (
    <Card
      sx={{
        width: "100%",
        p: 0,
        justifyContent: "space-between",
        overflow: "hidden",
        flexGrow: 1,
      }}
    >
      <Header {...headerProps} />

      {children}
    </Card>
  );
}

function Header({ header, description }: HeaderProps) {
  const lines = typeof description === "string" ? [description] : description;
  return (
    <Stack sx={{ p: CARD_PADDING }} spacing={1}>
      <Typography variant="h3">{header} </Typography>
      {lines.map((line, i) => (
        <Typography key={i} variant="body2" color="text.secondary">
          {line}
        </Typography>
      ))}
    </Stack>
  );
}
