import { TypographyOptions } from "@mui/material/styles/createTypography";
import { MEDIA } from "../../constants";
import { FONT_WEIGHTS } from "./constants";

const isMobile = MEDIA.MD.below;
const typography: TypographyOptions = {
  fontSize: 18,
  fontFamily:
    "PP Neue Montreal, Helvetica Neue, Helvetica -apple-system, sans-serif",
  subtitle1: {
    fontSize: "72px",
    lineHeight: "72px",
    fontWeight: FONT_WEIGHTS.bold,
    [isMobile]: {
      fontSize: "45px",
      lineHeight: "45px",
    },
  },
  h1: {
    fontSize: "48px",
    lineHeight: "48px",
    fontWeight: FONT_WEIGHTS.bold,
    [isMobile]: {
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  h2: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: FONT_WEIGHTS.bold,
    [isMobile]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  h3: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: FONT_WEIGHTS.bold,
    [isMobile]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  h4: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: FONT_WEIGHTS.bold,
    [isMobile]: {
      fontSize: "14px",
      lineHeight: "28px",
    },
  },
  body1: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: FONT_WEIGHTS.normal,
    [isMobile]: {
      fontSize: "14px",
      lineHeight: "28px",
    },
  },
  body2: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: FONT_WEIGHTS.normal,
    [isMobile]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  caption: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: FONT_WEIGHTS.normal,
    [isMobile]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
};

export default typography;
