import { PaperProps, Stack, styled } from "@mui/material";
import { BORDER_RADIUS } from "../theme";
import { Link } from "react-router-dom";

export function Card({ children, sx, ...props }: PaperProps) {
  return (
    <Stack
      sx={{
        padding: 3,
        gap: 2,
        backgroundColor: ({ palette }) => palette.background.paper,
        border: ({ palette }) => `1px solid ${palette.divider}`,
        borderRadius: BORDER_RADIUS,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
}

export const Span = styled("span")({});

export const AppLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
});
