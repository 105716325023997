import { PaletteOptions } from "@mui/material";
import { PRIMARY_COLOR } from "./constants";
import { colorToAlpha } from "./util";

const palette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: colorToAlpha(PRIMARY_COLOR, 0.95),
  },
  secondary: {
    main: "#394EFF",
  },
  background: {
    default: "#000000",
    paper: "#0D0D0D",
  },
  success: {
    main: "#06DB0C",
  },
  error: {
    main: "#F54545",
  },
  warning: {
    main: "#FFD15C",
  },
  info: {
    main: "#4A88FF",
  },
  divider: colorToAlpha(PRIMARY_COLOR, 0.05),
  text: {
    primary: colorToAlpha(PRIMARY_COLOR, 0.95),
    secondary: colorToAlpha(PRIMARY_COLOR, 0.6),
    disabled: colorToAlpha(PRIMARY_COLOR, 0.4),
  },
};
export default palette;
