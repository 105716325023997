import { MaxWidthWrapper, Page } from "../Page";
import { Typography } from "@mui/material";
import { usePrivacyPolicyQuery } from "../../reducers";
import { RenderApiMarkdown } from "../common";

export default function PrivacyPolicy() {
  const { data: terms } = usePrivacyPolicyQuery();

  return (
    <Page>
      <MaxWidthWrapper>
        <Typography variant="h1">Privacy Policy</Typography>
        <RenderApiMarkdown rawMarkdown={terms} />
      </MaxWidthWrapper>
    </Page>
  );
}
