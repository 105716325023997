import { Stack, Typography } from "@mui/material";
import { Column, ExternalLink, MediaStack } from "../common";
import { colorToAlpha, OUTER_MARGIN, SPACING_PX_VAL } from "../theme";
import { MaxWidthWrapper } from "../Page";
import { ArrowForward } from "@mui/icons-material";
import {
  GET_IN_TOUCH,
  MEDIA,
  NAV_BREAKPOINT,
  SCREENSHOTS,
} from "../../constants";

export default function Prime() {
  return (
    <Column
      sx={{
        width: `calc(100% - ${SPACING_PX_VAL * OUTER_MARGIN * 2}px)`,
        m: OUTER_MARGIN,
        backgroundColor: ({ palette }) => palette.background.paper,
      }}
      alignItems="center"
    >
      <MaxWidthWrapper
        maxWidth="1000px"
        spacing={8}
        sx={{ py: 6, alignItems: "center" }}
      >
        <Header />

        <Screenshot />
        <Benefits />
      </MaxWidthWrapper>
    </Column>
  );
}

const ASPECT_RATIO = 1134 / 737;
function Screenshot() {
  const width = 50;
  const height = (width * 1) / ASPECT_RATIO;

  return (
    <Stack
      aria-label="Screenshot of a Prime Loan"
      sx={{
        width: width + "vw",
        height: height + "vw",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundImage: ({ palette }) =>
          `linear-gradient(transparent 50%, ${colorToAlpha(
            palette.background.paper,
            0.6
          )} 80%, ${palette.background.paper} 100%), url(${
            SCREENSHOTS.primeLoan
          })`,
      }}
    />
  );
}

function Header() {
  return (
    <MediaStack
      sxAbove={{ alignItems: "flex-start" }}
      spacing={6}
      mobileSpacing={3}
    >
      <Typography sx={{ width: "100%", maxWidth: "500px" }} variant="h1">
        Powering institutional-grade solutions with Prime.
      </Typography>
      <Column spacing={1}>
        <Typography sx={{ width: "100%" }} color="text.secondary">
          Benefit from the demand and liquidity of permissionless finance with
          native features for compliance, control, security on Loopscale Prime.
        </Typography>
        <ExternalLink target="_blank" href={GET_IN_TOUCH}>
          <Typography sx={{ hover: { opacity: 0.7 } }}>
            Get in touch <ArrowForward />
          </Typography>
        </ExternalLink>
      </Column>
    </MediaStack>
  );
}

const BENEFITS = [
  {
    header: "Curated opportunities",
    description: "Verified counterparties and bespoke structures",
  },
  {
    header: "Scalable allocation",
    description: "Syndicated deals and tokenized asset collateral",
  },
  {
    header: "On and off-ramps",
    description: "Fiat transfers with high limits and low fees",
  },
  {
    header: "Passkey wallets",
    description: "Secure MPC infrastructure for simplified asset management",
  },
];
function Benefits() {
  return (
    <Stack
      sx={{
        display: "grid",
        gridGap: `${2 * SPACING_PX_VAL}px`,
        gridTemplateColumns: Array(BENEFITS.length).fill("1fr").join(" "),
        [NAV_BREAKPOINT.below]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [MEDIA.SM.below]: {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      {BENEFITS.map(({ header, description }, i) => (
        <Column spacing={1} key={i}>
          <Typography> {header} </Typography>
          <Typography color="text.secondary" variant="body2">
            {description}
          </Typography>
        </Column>
      ))}
    </Stack>
  );
}
