import { Stack, Typography } from "@mui/material";
import { Column, Image, Row } from "../common";
import { COMPANIES } from "../../constants";
import { camelCaseToTitleCase } from "../theme";

const LOGOS: (keyof typeof COMPANIES)[] = [
  "coinbase",
  "coinFund",
  "jump",
  "solanaVentures",
  "r40",
];
export default function Partners() {
  return (
    <Column alignItems="center" textAlign="center" spacing={4}>
      <Typography variant="h3"> Investors & Partners </Typography>
      <Row width="100%" justifyContent="center" spacing={4} wrap>
        {LOGOS.map((logo) => (
          <Stack key={logo} flexGrow={1}>
            <Image
              alt={camelCaseToTitleCase(logo)}
              variant="fixed-height"
              height="50px"
              mobileHeight="30px"
              width="auto"
              src={COMPANIES[logo]}
            />
          </Stack>
        ))}
      </Row>
    </Column>
  );
}
