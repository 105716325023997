import { ReactNode, createContext, useContext, useRef } from "react";

type RefType = React.RefObject<HTMLDivElement> | null;
const HomeScrollContext = createContext<{
  collateralRef: RefType;
  benefitsRef: RefType;
  featuresRef: RefType;
  partnersRef: RefType;
  primeRef: RefType;
}>({ collateralRef: null, benefitsRef: null, featuresRef: null, partnersRef: null, primeRef: null });

export const HomeScrollContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const collateralRef = useRef<HTMLDivElement>(null);
  const benefitsRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const primeRef = useRef<HTMLDivElement>(null);

  return (
    <HomeScrollContext.Provider
      value={{ collateralRef, benefitsRef, featuresRef, partnersRef, primeRef }}
    >
      {children}
    </HomeScrollContext.Provider>
  );
};

export function useHomeScrollContext() {
  const context = useContext(HomeScrollContext);

  const options: ScrollIntoViewOptions = { behavior: "smooth", block: "start" };
  const collateral = () =>
    context.collateralRef?.current?.scrollIntoView(options);
  const benefits = () => context.benefitsRef?.current?.scrollIntoView(options);
  const features = () =>
    context.featuresRef?.current?.scrollIntoView(options);
  const partners = () =>
    context.partnersRef?.current?.scrollIntoView(options);
  const prime = () =>
    context.primeRef?.current?.scrollIntoView(options);

  const firstOnPage = collateral;

  const scrollTo = { firstOnPage, collateral, benefits, features, partners, prime };

  return { ...context, scrollTo };
}
