import {
  Button,
  Collapse,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import {
  NAV_BREAKPOINT,
  HOME_SLUG,
  APP_SIGNUP,
  DOCS_URL,
  BLOG_URL,
  CAREERS_URL,
  TWITTER_URL,
  DISCORD_URL,
} from "../../constants";
import { Column, ExternalLink, WordmarkSvg, Row } from "../common";
import { useMemo, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useOutsideAlerter } from "../../util";
import { Link } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

type NavOption = {
  text: string;
  primary?: boolean;
  href?: string;
  onClick?: () => void;
  options?: { text: string; href: string }[];
  hide?: boolean;
};

function useOptions() {
  const cta: NavOption[] = [
    {
      text: "Join the Beta",
      href: APP_SIGNUP,
      primary: true,
    },
  ];

  const main: NavOption[] = [
    {
      text: "Docs",
      href: DOCS_URL,
    },
    {
      text: "Blog",
      href: BLOG_URL,
    },
    {
      text: "Careers",
      href: CAREERS_URL,
    },
    {
      text: "Community",
      options: [
        { href: TWITTER_URL, text: "X" },
        { href: DISCORD_URL, text: "Discord" },
      ],
    },
  ];

  const all = [...main, ...cta];

  return { cta, main, all };
}

export default function Navbar() {
  return (
    <>
      <Mobile />
      <Desktop />
    </>
  );
}

function Desktop() {
  const { main, cta } = useOptions();
  return (
    <Row sx={{ [NAV_BREAKPOINT.below]: { display: "none" } }} spaceBetween>
      <Logo />
      <Row spacing={2}>
        <Options options={main} />
      </Row>
      <Options options={cta} />
    </Row>
  );
}

function Options({ options }: { options: NavOption[] }) {
  return (
    <>
      {options.map((o, i) =>
        o.href ? (
          <ExternalLink key={i} href={o.href}>
            <Item {...o} />
          </ExternalLink>
        ) : (
          <Item key={i} {...o} />
        )
      )}
    </>
  );
}

function Item(o: NavOption) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        anchorEl={anchorEl}
      >
        <Column px={2} py={1} spacing={1}>
          {o.options?.map((o, i) => (
            <ExternalLink key={i} sx={{ width: "max-content" }} href={o.href}>
              <Button variant="text"> {o.text} </Button>
            </ExternalLink>
          ))}
        </Column>
      </Popover>
      <Button
        onClick={(e) => {
          if (o.options) {
            setAnchorEl(e.currentTarget);
          }
          o.onClick?.();
        }}
        variant={o.primary ? "outlined" : "text"}
      >
        {o.text}
        {o.options && <ExpandMore />}
      </Button>
    </>
  );
}

function Mobile() {
  const [open, setOpen] = useState(false);
  const { all: options } = useOptions();
  const close = () => setOpen(false);

  const flatOptions = useMemo(() => {
    let flatOptions: NavOption[] = [];
    for (const option of options) {
      if (!option.options) {
        flatOptions.push(option);
      } else {
        flatOptions.push(...option.options);
      }
    }
    return flatOptions;
  }, [options]);

  const ref = useOutsideAlerter(close);

  return (
    <Stack
      ref={ref}
      sx={{ position: "relative", [NAV_BREAKPOINT.above]: { display: "none" } }}
    >
      <Row spaceBetween>
        <Logo />
        <IconButton
          sx={{
            svg: { fontSize: ({ typography }) => typography.h3.fontSize },
            p: 1,
          }}
          color="primary"
          onClick={() => setOpen((prev) => !prev)}
        >
          <MenuIcon />
        </IconButton>
      </Row>

      <Collapse sx={{ width: "100%" }} in={open}>
        <Column my={2} spacing={1}>
          <Options options={flatOptions} />
        </Column>
      </Collapse>
    </Stack>
  );
}

const Logo = () => (
  <Link to={HOME_SLUG}>
    <Typography>
      <WordmarkSvg />
    </Typography>
  </Link>
);
