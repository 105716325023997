import {
  Middleware,
  PayloadAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { docsApi } from "./docsApi";

enableMapSet();

const appReducer = combineReducers({
  [docsApi.reducerPath]: docsApi.reducer,
});
const middleware: Middleware[] = [docsApi.middleware];
export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: PayloadAction) => {
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
