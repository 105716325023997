import { ReactNode } from "react";
import {
  BaxusSvg,
  BonkSvg,
  Card,
  CircleSvg,
  ExternalLink,
  HeliumSvg,
  HivemapperSvg,
  JitoSvg,
  MarinadeSvg,
  MeteoraSvg,
  OndoSvg,
  OrcaSvg,
  OreSvg,
  PaxosSvg,
  PythSvg,
  Row,
  SanctumSvg,
  SolanaSvg,
  TetherSvg,
  WenSvg,
  WormholeSvg,
} from "../common";
import { Stack, Typography } from "@mui/material";
import { APP_URL } from "../../constants";
import Marquee from "react-fast-marquee";
import { OUTER_MARGIN } from "../theme";

export default function FeaturedIssuers() {
  return (
    <Stack spacing={2}>
      <Marquee pauseOnHover speed={50}>
        {ISSUERS.map((issuer, i) => (
          <IssuerCard key={i} {...issuer} />
        ))}
      </Marquee>
    </Stack>
  );
}

type IssuerCardProps = {
  svg: ReactNode;
  header: string;
  description: string;
  slug: string;
};
const ISSUERS: IssuerCardProps[] = [
  {
    svg: <SolanaSvg />,
    header: "Native-Staked SOL",
    description: "SOL staked directly with a validator",
    slug: "staked_sol",
  },
  {
    svg: <MeteoraSvg />,
    header: "Meteora",
    description: "Liquidity and vault positions",
    slug: "meteora",
  },
  {
    svg: <BaxusSvg />,
    header: "BAXUS",
    description: "Whiskey bottles and casks",
    slug: "baxus",
  },
  {
    svg: <SanctumSvg />,
    header: "Sanctum",
    description: "CLOUD and Liquid staking tokens (LSTs)",
    slug: "sanctum",
  },
  {
    svg: <HeliumSvg />,
    header: "Helium",
    description: "HNT, IOT, and MOBILE ",
    slug: "helium",
  },
  {
    svg: <OrcaSvg />,
    header: "Orca",
    description: "ORCA and Orca Whirlpool liquidity positions",
    slug: "orca",
  },
  {
    svg: <OndoSvg />,
    header: "Ondo",
    description: "USDY liquid treasury bills",
    slug: "ondo",
  },
  {
    svg: <JitoSvg />,
    header: "Jito",
    description: "JTO and JitoSOL",
    slug: "jito",
  },
  {
    svg: <OreSvg />,
    header: "Regolith Labs",
    description: "ORE",
    slug: "regolith",
  },
  {
    svg: <HivemapperSvg />,
    header: "Hivemapper",
    description: "HONEY",
    slug: "hivemapper",
  },
  {
    svg: <BonkSvg />,
    header: "Bonk",
    description: " Staked and liquid BONK",
    slug: "bonk",
  },
  {
    svg: <TetherSvg />,
    header: "Tether",
    description: "USDT and other Tether assets",
    slug: "tether",
  },
  {
    svg: <WormholeSvg />,
    header: "Wormhole",
    description: "W and other Wormhole wrapped assets",
    slug: "wormhole",
  },
  {
    svg: <WenSvg />,
    header: "Wen",
    description: "Staked and liquid WEN",
    slug: "wen",
  },
  {
    svg: <CircleSvg />,
    header: "Circle",
    description: "USDC and EURC",
    slug: "circle",
  },
  {
    svg: <MarinadeSvg />,
    header: "Marinade",
    description: "MNDE and mSOL",
    slug: "marinade",
  },
  {
    svg: <PythSvg />,
    header: "Pyth",
    description: "Staked and liquid PYTH",
    slug: "pyth",
  },
  {
    svg: <PaxosSvg />,
    header: "Paxos",
    description: "PYUSD",
    slug: "paxos",
  },
];

function IssuerCard({ svg, header, description, slug }: IssuerCardProps) {
  return (
    <ExternalLink
      sx={{ mx: OUTER_MARGIN / 2, mb: OUTER_MARGIN, minWidth: "300px" }}
      href={`${APP_URL}/issuers/${slug}`}
    >
      <Card sx={{ gap: 1, flexGrow: 1 }}>
        <Row spaceBetween>
          <Typography variant="h4"> {header} </Typography>
          <Typography> {svg} </Typography>
        </Row>

        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Card>
    </ExternalLink>
  );
}
