import { ThemeOptions } from "@mui/material";
import { colorToAlpha } from "./util";
import { PRIMARY_COLOR, SPACING_PX_VAL } from "./constants";

const HOVER_COLOR = colorToAlpha(PRIMARY_COLOR, 0.7);
const components: ThemeOptions["components"] = {
  MuiLink: {
    defaultProps: { target: "_blank" },
    styleOverrides: {
      root: {
        color: "#1D9BF0",
        textDecorationColor: "#1D9BF0",
      },
    },
  },
  MuiIconButton: {
    defaultProps: { disableRipple: true },
    styleOverrides: {
      root: ({ theme: { palette, typography } }) => ({
        svg: {
          fontSize: typography.fontSize,
        },
        padding: "15px",
        color: palette.text.primary,
        ":hover": {
          backgroundColor: colorToAlpha(PRIMARY_COLOR, 0.05),
        },
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        alignItems: "center",
        display: "flex",
        gap: SPACING_PX_VAL + "px",
        svg: { fontSize: "inherit" },
      },
    },
    defaultProps: { color: "text.primary" },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      outlined: {
        borderColor: colorToAlpha(PRIMARY_COLOR, 0.2),
      },
      text: {
        padding: 0,
        minWidth: 0,
        width: "max-content",
        gap: 1,
        ":hover": { background: "none", opacity: 0.7 },
      },
      root: ({ ownerState: { variant, fullWidth } }) => ({
        textTransform: "initial",
        display: "flex",
        gap: 1 * SPACING_PX_VAL,
        svg: { fontSize: "inherit" },
        width: fullWidth ? "100%" : "max-content",
        ":hover": {
          backgroundColor: variant === "contained" ? HOVER_COLOR : undefined,
        },
      }),
    },
  },
};

export default components;
