import { APP_SIGNUP, GRAPHICS, MEDIA } from "../../constants";
import { Column, ExternalLink, Span } from "../common";
import { Box, Button, Stack, styled, Typography } from "@mui/material";

const BREAKPOINT = MEDIA.LG;
export default function Hero() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1, py: 6, alignItems: "center" }}>
      <Content />
      <VideoContent />
    </Stack>
  );
}

function Content() {
  return (
    <Column
      sx={{
        maxWidth: "700px",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexGrow: 1,
      }}
      spacing={4}
    >
      <Column spacing={1}>
        <Typography variant="subtitle1" sx={{ display: "inline" }}>
          Borrow against{" "}
          <Span sx={{ color: ({ palette }) => palette.secondary.main }}>
            any asset
          </Span>{" "}
          at the{" "}
          <Span sx={{ color: ({ palette }) => palette.secondary.main }}>
            best rate
          </Span>
        </Typography>
        <Typography sx={{ fontSize: "18px" }} color="text.secondary">
          Unlock fixed-rate liquidity with Loopscale’s Creditbook, the next
          generation of blockchain capital markets.
        </Typography>
      </Column>

      <ExternalLink sx={{ width: "max-content" }} href={APP_SIGNUP}>
        <Button>Join the Beta</Button>
      </ExternalLink>
    </Column>
  );
}

const Video = styled("video")({});
function VideoContent() {
  return (
    <Stack
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: "400px",
        height: "400px",
        marginLeft: "-20px !important",
        marginBottom: "-170px !important",
        [BREAKPOINT.below]: {
          width: "300px",
          height: "300px",
          marginBottom: "-120px !important",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "absolute",
          background: ({ palette }) =>
            `radial-gradient(transparent 40%, ${palette.background.paper} 100%)`,
        }}
      />
      <Video
        playsInline
        muted
        autoPlay
        loop
        sx={{
          mixBlendMode: "lighten",
        }}
        src={GRAPHICS.marbleVideo}
        width="100%"
        height="100%"
      />
    </Stack>
  );
}
