import { IconButton, Typography } from "@mui/material";
import {
  AppLink,
  Column,
  DiscordSvg,
  ExternalLink,
  LinkedInSvg,
  MediaStack,
  Row,
  TwitterSvg,
  WordmarkSvg,
} from "../common";
import {
  APP_PADDING,
  APP_URL,
  BLOG_URL,
  CAREERS_URL,
  DISCORD_URL,
  DOCS_URL,
  FEEDBACK_URL,
  LINKED_IN_URL,
  NAV_BREAKPOINT,
  PRIVACY_SLUG,
  SUPPORT_URL,
  TERMS_SLUG,
  TWITTER_URL,
} from "../../constants";
import { OUTER_MARGIN, SPACING_PX_VAL } from "../theme";
import { SxType } from "../../types";
const BREAKPOINT = NAV_BREAKPOINT;

export default function Footer() {
  return (
    <Column
      sx={{
        width: `calc(100% - ${
          SPACING_PX_VAL * (OUTER_MARGIN + APP_PADDING.desktop) * 2
        }px)`,
        m: OUTER_MARGIN,
        px: APP_PADDING.desktop,
        [BREAKPOINT.below]: {
          px: APP_PADDING.mobile,
          width: `calc(100% - ${
            SPACING_PX_VAL * (OUTER_MARGIN + APP_PADDING.mobile) * 2
          }px)`,
        },
        py: 8,
        backgroundColor: ({ palette }) => palette.background.paper,
      }}
      spacing={4}
    >
      <MediaStack spaceBetweenRow query={BREAKPOINT}>
        <Column spacing={2}>
          <Typography variant="h2">
            <WordmarkSvg />
          </Typography>
          <Socials />
        </Column>
        <Products />
      </MediaStack>
      <Disclaimers />
    </Column>
  );
}

const SOCIAL_SX: SxType = {
  background: "none",
  ":hover": { background: "none", opacity: 0.7 },
  p: 0,
};

function Socials() {
  return (
    <Row spacing={2}>
      <ExternalLink href={TWITTER_URL}>
        <IconButton sx={SOCIAL_SX}>
          <TwitterSvg />
        </IconButton>
      </ExternalLink>
      <ExternalLink href={DISCORD_URL}>
        <IconButton sx={SOCIAL_SX}>
          <DiscordSvg />
        </IconButton>
      </ExternalLink>
      <ExternalLink href={LINKED_IN_URL}>
        <IconButton sx={SOCIAL_SX}>
          <LinkedInSvg />
        </IconButton>
      </ExternalLink>
    </Row>
  );
}

const TEXT_LINK_SX: SxType = { ":hover": { opacity: 0.7 } };
function Disclaimers() {
  return (
    <Column>
      <Typography variant="caption">© 2024 Standard Labs, Inc.</Typography>

      <AppLink to={TERMS_SLUG}>
        <Typography sx={TEXT_LINK_SX} color="text.secondary" variant="caption">
          Terms of Service
        </Typography>
      </AppLink>

      <AppLink to={PRIVACY_SLUG}>
        <Typography sx={TEXT_LINK_SX} color="text.secondary" variant="caption">
          Privacy Policy
        </Typography>
      </AppLink>
    </Column>
  );
}

type Category = { label: string; links: { href: string; label: string }[] };

const CATEGORIES: Category[] = [
  {
    label: "Products",
    links: [
      { href: `${APP_URL}/borrow`, label: "Borrow" },
      { href: `${APP_URL}/lend`, label: "Lend" },
      { href: `${APP_URL}/prime`, label: "Prime" },
    ],
  },
  {
    label: "Resources",
    links: [
      { href: DOCS_URL, label: "Docs" },
      { href: SUPPORT_URL, label: "Support" },
      { href: FEEDBACK_URL, label: "Feedback" },
    ],
  },
  {
    label: "Company",
    links: [
      { href: BLOG_URL, label: "Blog" },
      { href: CAREERS_URL, label: "Careers" },
    ],
  },
];
function Products() {
  return (
    <MediaStack
      sxAbove={{ alignItems: "flex-start" }}
      spacing={3}
      query={BREAKPOINT}
    >
      {CATEGORIES.map(({ label, links }, i) => (
        <Column spacing={0.5} key={i}>
          <Typography variant="body1">{label}</Typography>

          {links.map((link, j) => (
            <ExternalLink key={j} href={link.href}>
              <Typography
                sx={TEXT_LINK_SX}
                color="text.secondary"
                variant="body2"
              >
                {link.label}
              </Typography>
            </ExternalLink>
          ))}
        </Column>
      ))}
    </MediaStack>
  );
}
