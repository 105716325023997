import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const docsApi = createApi({
  reducerPath: "docsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://loopscale-site.s3.amazonaws.com/docs",
  }),
  endpoints: (builder) => ({
    termsOfService: builder.query<string, void>({
      query() {
        return {
          responseHandler: "text",
          url: "terms.md",
          method: "GET",
        };
      },
    }),
    privacyPolicy: builder.query<string, void>({
      query() {
        return {
          responseHandler: "text",
          url: "privacy.md",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useTermsOfServiceQuery, usePrivacyPolicyQuery } = docsApi;
