import { Box, Stack } from "@mui/material";
import Markdown from "react-markdown";

// https://github.com/remarkjs/react-markdown/issues/772
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

export function RenderApiMarkdown({
  rawMarkdown,
}: {
  rawMarkdown: string | undefined;
}) {
  if (!rawMarkdown) return <Stack sx={{ height: "100vh" }} />;
  return <RenderMarkdown rawMarkdown={rawMarkdown} />;
}

export function RenderMarkdown({ rawMarkdown }: { rawMarkdown: string }) {
  return (
    <Box>
      <Markdown>{rawMarkdown}</Markdown>
    </Box>
  );
}
