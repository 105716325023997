import { NAV_HEIGHT, APP_PADDING } from "../../constants";

export const SPACING_PX_VAL = 8;
export const SPACING_PX_TEXT = `${SPACING_PX_VAL}px`;
export const OUTER_MARGIN = 2;
export const LIGHT_SHADOW = "0px 0px 10px 0px rgba(0, 0, 0, 0.05)";
export const LIGHT_SMALL_SHADOW = `0px 0px 10px rgba(0, 0, 0, 0.05)`;
export const PRIMARY_COLOR = "#FFFFFF";
export const BORDER_RADIUS = SPACING_PX_VAL / 2 + "px";
export const FONT_SIZES = {
  subtitle1: 72,
  h1: 48,
  h2: 32,
  h3: 24,
  h4: 20,
  body1: 20,
  body2: 18,
  caption: 16,
};
export const LINE_HEIGHT_MULTIPLIER = 1.3;
export const LETTER_SPACING = "0em";

export const FONT_WEIGHTS = { normal: 400, bold: 500 };
export const FULL_HEIGHT_WITH_NAV = `calc(100vh - ${NAV_HEIGHT * 2}px - ${
  SPACING_PX_VAL * APP_PADDING.mobile * 2
}px)`;

export const DARK_COlORS = {
  body: "#E5E5E5",
  caption: "#999999",
  disabled: "#666666",
};
