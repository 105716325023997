import { ReactNode } from "react";
import { AppBar, Stack } from "@mui/material";
import { APP_MAX_WIDTH, APP_PADDING, NAV_BREAKPOINT } from "../constants/app";
import { SxType } from "../types";
import { OUTER_MARGIN, SPACING_PX_VAL } from "./theme";
import { Footer } from "./home";
import { Navbar } from "./containers";
import { Column } from "./common";

export function Page({
  children,
  spacing,
}: {
  children: ReactNode;
  spacing?: number;
}) {
  return (
    <Column justifyContent="stretch">
      <AppBar
        elevation={0}
        sx={{
          p: OUTER_MARGIN,
          px: APP_PADDING.desktop,
          [NAV_BREAKPOINT.below]: { px: APP_PADDING.mobile },
          background: ({ palette }) => palette.background.default,
        }}
        position="sticky"
      >
        <Navbar />
      </AppBar>
      <Stack
        spacing={spacing}
        component="main"
        sx={{
          background: ({ palette: { background } }) => background.default,
          display: "flex",
          width: "100vw",
          maxWidth: "100vw",
          alignItems: "center",
        }}
      >
        {children}
      </Stack>
      <Footer />
    </Column>
  );
}

export function MaxWidthWrapper({
  children,
  sx,
  spacing,
  maxWidth = APP_MAX_WIDTH,
}: {
  children: ReactNode;
  sx?: SxType;
  spacing?: number;
  maxWidth?: string;
}) {
  return (
    <Stack
      spacing={spacing}
      id="max-width-wrapper"
      sx={{
        width: `calc(100% - ${APP_PADDING.desktop * 2 * SPACING_PX_VAL}px)`,
        p: APP_PADDING.desktop,
        [NAV_BREAKPOINT.below]: {
          p: APP_PADDING.mobile,
          width: `calc(100% - ${APP_PADDING.mobile * 2 * SPACING_PX_VAL}px)`,
        },
        maxWidth,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
