import { Stack, Typography } from "@mui/material";
import { Card, Column, Image, MediaStack } from "../common";
import { GRAPHICS, MEDIA } from "../../constants";

const BREAKPOINT = MEDIA.LG;
export default function Orderbook() {
  return (
    <>
      <MediaStack
        query={BREAKPOINT}
        sx={{ position: "relative" }}
        sxBelow={{ alignItems: "flex-start" }}
        sxAbove={{ alignItems: "stretch" }}
        spacing={6}
      >
        <Image
          sx={{ position: "absolute", top: "-15vw", zIndex: -1, left: "-20vw" }}
          alt="token categories"
          variant="fixed-width"
          width="60vw"
          height="auto"
          src={GRAPHICS.arcs}
        />
        <Image
          alt="rates credit book"
          variant="fixed-height"
          width="auto"
          height="100%"
          query={BREAKPOINT}
          mobileHeight="250px"
          src={GRAPHICS.orderbook}
        />
        <Column spacing={3}>
          <Column spacing={1}>
            <Typography variant="h1">A new frontier. </Typography>
            <Typography color="text.secondary">
              Multi-trillion dollar opportunities, from repurchase agreements to
              receivables, require a primitive that enables structuring and
              market coordination.
            </Typography>
          </Column>
          <Cards />
        </Column>
      </MediaStack>
    </>
  );
}

const CARDS: { header: string; description: string }[] = [
  {
    header: "Configurable Orders",
    description:
      "Customizable, isolated loan structures offers precise risk management.",
  },
  {
    header: "Modular Loans",
    description:
      "Segregated collateral enables composability with complex assets.",
  },
  {
    header: "Optimized Terms",
    description:
      "Robust order book matching increases lend yield and decreases borrow costs.",
  },
  {
    header: "Virtual Liquidity",
    description:
      "Parameter abstraction provide market depth without limiting scalability.",
  },
];
function Cards() {
  return (
    <Stack
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "1fr 1fr",
        [BREAKPOINT.below]: { gridTemplateColumns: "1fr" },
      }}
    >
      {CARDS.map((card, i) => (
        <Card key={i} sx={{ p: 2, gap: 0.5 }}>
          <Typography variant="h4">{card.header} </Typography>
          <Typography variant="body2" color="text.secondary">
            {card.description}
          </Typography>
        </Card>
      ))}
    </Stack>
  );
}
