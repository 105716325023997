export const DOMAIN = "loopscale.com";
export const APP_URL = `https://app.${DOMAIN}`;
export const APP_SIGNUP = APP_URL;
export const TWITTER_URL = "https://x.com/LoopscaleLabs";
export const DISCORD_URL = "https://discord.gg/loopscale";
export const LINKED_IN_URL = "https://www.linkedin.com/company/LoopscaleLabs";

export const DOCS_URL = `https://docs.${DOMAIN}`;
export const BLOG_URL = `https://blog.${DOMAIN}`;
export const CAREERS_URL = `https://careers.${DOMAIN}`;
export const FEEDBACK_URL = `http://feedback.${DOMAIN}`;
export const ANALYTICS_URL = `https://analytics.${DOMAIN}`;
export const SUPPORT_URL =
  "https://discord.com/channels/895116209958297631/932703647236771850";
export const GET_IN_TOUCH = "mailto:team@loopscale.com";
